import React, { Fragment, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../activities.scss";
import { MDBIcon } from "mdbreact";
import NoResult from "../../no_result/no_result";

type BlogDetailsProps = {
  blog: {
    id: number;
    title: string;
    description: string;
    author: string;
    created_at: string;
  };
  months: string[];
};

const BlogDetails = (props: BlogDetailsProps) => {
  let history: any = useHistory();
  let location: any = useLocation();
  let companyName = location.pathname.split("/")[1];

  const clickLocation = () => {
    history.push(`/${companyName}/activities/blogs`);
  };
  return (
    <Fragment>
      {props.blog ? (
        <div className="activity" key={props.blog.id}>
          <div className="goback">
            <span onClick={() => clickLocation()}>back</span>
          </div>
          <div className="activity-title details">{props.blog.title}</div>
          <div className="below-title">
            <span className="author">Author : {props.blog.author} |</span>
            <span className="date">
              <MDBIcon far icon="calendar-alt" />{" "}
              {props.blog.created_at.split(" ")[0]}
            </span>
          </div>
          <div className="activity-description active">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: props.blog.description }}
            />
          </div>
        </div>
      ) : (
        <NoResult title="data" />
      )}
    </Fragment>
  );
};

export default BlogDetails;
