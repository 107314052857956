import React, { Fragment, useState } from "react";
import Layout from "../../common/layout";
import BlogsList from "./blogs/blogsList";
import CareerList from "./career/careerList";
import NewsList from "./news/newsList";
import Footer from "../../footer/footer";
import { useParams } from "react-router";

type ActivitiesProps = {
  blogs: [];
  careers: [];
  news: [];
};
const Activities = (props: ActivitiesProps) => {
  var months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const renderSwitch = (activeTab: string) => {
    switch (activeTab) {
      case "career":
        return <CareerList careers={props.careers} months={months} />;
      case "blogs":
        return <BlogsList blogs={props.blogs} months={months} />;
      case "news":
        return <NewsList news={props.news} months={months} />;
      default:
        return "";
    }
  };
  let { page } = useParams();
  return (
    <Fragment>
      {renderSwitch(page)}
      <Footer />
    </Fragment>
  );
};

export default Activities;
