import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import ActivityData from "../../activities-json";
import BlogDetails from "./blogDetails";
import "../../activities.scss";

type BlogListProps = {
  blogs: [];
  months: string[];
};

const SingleBlog = (props: BlogListProps) => {
  let location: any = useLocation();
  let blogId = location.pathname.split("/")[4];

  const Data: any =
    props.blogs.length > 0 &&
    props.blogs.find((filterData: any) => {
      return filterData.id === blogId;
    });
  return (
    <div>
      <div className="container" id="introduction-details">
        <div className="activities" id="blogs">
          <BlogDetails blog={Data} months={props.months} />
        </div>
      </div>
    </div>
  );
};

export default SingleBlog;
