import React from "react";
import { Route, Switch, BrowserRouter, withRouter } from "react-router-dom";
import TitlePage from "./title-page/titlePage";
import ArcHomePage from "./arc/homepage/homepage";
import AboutUs from "./arc/aboutus/about";
import ContactPage from "./arc/contact/contact";
import Activities from "./arc/activities/activities";
import Activity from "./arc/activities/activity";
import WorkPage from "./arc/work/work";
import WorkDetail from "./arc/work/work-detail.jsx";
import MenuBar from "./arc/menu/menu";
import { RootState } from "../store/root-state";
import { Dispatch } from "redux";
import { companyInfoLoad } from "../store/data/company-info/company-info";
import { connect, useSelector } from "react-redux";
import ContactData from "../components/arc/contact/contact.json";
import Layout from "./common/layout";
import ActivitiesData from "./arc/activities/activities-json";

const mapStateToProps = (state: RootState) => {
  const { data } = state.dataModule;

  return {
    // data: [...(data || []), rootRoute],
    data: [...(data || [])],
    company: state.companyInfoModule && state.companyInfoModule.data,
    companies: state.dataModule && state.dataModule.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadCompanyInfo: (id: string) => dispatch(companyInfoLoad(id)),
  };
};
class Routes extends React.Component<any, any> {
  componentDidMount() {
    const companies = this.props.data as any[];
    this.props.location.pathname = `/arc` //remove for 2 or more comp
    const company = companies.find(
      (company: any) =>
        company.name.toLowerCase() === 
        this.props.location.pathname.split("/")[1]
    );
    if (
      company != undefined
      // &&

      // (!company ||
      //   company.name.toLowerCase() !== this.props.location.pathname.split("/")[1])
    ) {
      // debugger;
      console.log(company.id);
      this.props.loadCompanyInfo(company.id);
    }
  }

  componentDidUpdate(prevProps: any) {
    const companies = this.props.data as any[];
    this.props.location.pathname = `/arc` //remove for 2 or more comp
    const company = companies.find(
      (company: any) =>
        company.name.toLowerCase() ===
        this.props.location.pathname.split("/")[1]
    );

    if (
      this.props.company == undefined ||
      (company != undefined &&
        (!company ||
          company.name.toLowerCase() !==
            this.props.location.pathname.split("/")[1]))
    ) {
      // debugger;
      console.log(company.id);
      this.props.loadCompanyInfo(company.id);
    }
  }

  render() {
    const DefaultRoutes = () => {
      return (
        <>
          <MenuBar
            workCategories={
              this.props.company && this.props.company.work_categories
            }
          />
          <Layout
            contact={
              this.props.company &&
              this.props.company.contacts &&
              this.props.company.contacts[0]
            }
            company={this.props.company}
          />
          <Switch>
            <Route
              path="/" //remove for 2 or more comp
              // path="/:companyName // use for 2 or more comp"
              exact={true}
              component={() => (
                <ArcHomePage
                  home_pages={
                    this.props.company?.home_pages??[]
                  }
                />
              )}
            />
            {/* <Route path="/eng" exact={true} component={ArcHomePage} /> */}
            <Route
              // path="/:companyName/about/:page"
              path="/:companyName/about"
              exact={true}
              component={() => (
                <AboutUs
                  introduction={
                    this.props.company && this.props.company.abouts != undefined
                      ? this.props.company.abouts[0].description
                      : []
                  }
                  team={
                    this.props.company && this.props.company.abouts != undefined
                      ? this.props.company.abouts[0].src
                      : []
                  }
                />
              )}
            />
            <Route
              path="/:companyName/activities/:page"
              exact={true}
              // component={}
              component={() => (
                <Activities
                  blogs={
                    this.props.company &&
                    this.props.company != undefined
                      ? this.props.company.blogs
                      : []
                  }
                  news={
                    this.props.company &&
                    this.props.company != undefined
                      ? this.props.company.news
                      : []
                  }
                  careers={
                    this.props.company &&
                    this.props.company != undefined
                      ? this.props.company.careers
                      : []
                  }
                />
              )}
            />
            <Route
              path="/:companyName/activities/:page/:id"
              exact={true}
              component={() => (
                <Activity
                  blogs={
                    this.props.company &&
                    this.props.company != undefined
                      ? this.props.company.blogs
                      : []
                  }
                  news={
                    this.props.company &&
                    this.props.company != undefined
                      ? this.props.company.news
                      : []
                  }
                  careers={
                    this.props.company &&
                    this.props.company != undefined
                      ? this.props.company.careers
                      : []
                  }
                />
              )}
            />
            <Route
              path="/:companyName/works/:category"
              exact={true}
              component={() => (
                <WorkPage
                  workCategories={
                    this.props.company &&
                    this.props.company.work_categories != undefined
                      ? this.props.company.work_categories
                      : []
                  }
                  works={
                    this.props.company && this.props.company.works != undefined
                      ? this.props.company.works
                      : []
                  }
                  company={this.props.company}
                />
              )}
            />
            <Route
              path="/:companyName/works/:category/work-detail/:id"
              exact={true}
              component={() => (
                <WorkDetail
                  workCategories={
                    this.props.company &&
                    this.props.company.work_categories != undefined
                      ? this.props.company.work_categories
                      : []
                  }
                  works={
                    this.props.company && this.props.company.works != undefined
                      ? this.props.company.works
                      : []
                  }
                  company={this.props.company}
                />
              )}
            />
            <Route
              path="/:companyName/contact"
              exact={true}
              component={() => (
                <ContactPage
                  contact={
                    this.props.company &&
                    this.props.company.contacts &&
                    this.props.company.contacts[0]
                  }
                  company={this.props.company}
                />
              )}
            />
          </Switch>
        </>
      );
    };
    return (
      <BrowserRouter>
        <Switch>
          {/* <Route path="/" exact={true} component={HomePageRoute} /> */}
          <Route
            path="/" //remove for 2 or more comp
            // path="/:companyName // use for 2 or more comp"
            component={DefaultRoutes}
            {...this.props}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
