import { createActions, handleAction, combineActions } from "redux-actions";
import { DataPayload } from "../data.payload";
import { COMPANY_INFO_LOAD, COMPANY_INFO_LOADED, COMPANY_INFO_ERROR } from "./company-info.actions";
import { Status } from "../Status";

export const {companyInfoLoad, companyInfoLoaded, companyInfoError} = createActions({
    [COMPANY_INFO_LOAD]: (id: string): DataPayload => ({status: Status.loading, data: { id }}),
    [COMPANY_INFO_LOADED]: (data: any): DataPayload => ({status: Status.loaded, data}),
    [COMPANY_INFO_ERROR]: (error: any): DataPayload => ({status: Status.error, data: error})
});

const defaultState = new DataPayload();

export const companyInfoHandler = handleAction<DataPayload, DataPayload>(
    combineActions(companyInfoLoad, companyInfoLoaded, companyInfoError),
    {
        next: (state, action) => {
            return action.payload;
        },
        throw: (state, action) => {
            return {status: Status.error, data: action.payload};
        }
    },
    defaultState
);
