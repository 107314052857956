import React, { Fragment } from "react";
import "./team.scss";
import teamimage from "../../../../assets/members/c1.jpg";
import teamimage2 from "../../../../assets/team-min.jpg";
import { MDBIcon } from "mdbreact";
import { apiBaseEndpoint } from "../../../../constant/endpoints";

type TeamProps = {
  image: string
}

class TeamPage extends React.Component<TeamProps, any> {
  render(){
    return (
      <>
        <div className="sample-img">
          <img src={apiBaseEndpoint+'/'+ `${this.props.image}`} alt="team-image" />
        </div>
      </>
    )
  }
}

export default TeamPage;
