import React, { useState, Fragment } from "react";
import Layout from "../../common/layout";
import Menu from "../menu/menu";
import "./homepage.scss";
import Footer from "../../footer/footer";
import ArcHomePageSlider from "../homepage/slider/image_video_slider";
import { RootState } from "../../../store/root-state";
import { Dispatch } from "redux";
import { mainDataLoad } from "../../../store/data/data-loader";
import { connect } from "react-redux";
import { apiBaseEndpoint } from "../../../constant/endpoints";

const mapStateToProps = (state: RootState) => {
  return { ...state.dataModule };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadData: () => dispatch(mainDataLoad()),
  };
};

type ArcHomePageProps = {
  loadData: () => void;
  home_pages: [];
};

class ArcHomePage extends React.Component<ArcHomePageProps, any> {
  render() {
    const homePages = this.props.home_pages;
    const image_video_data = (homePages || []).map((homePage: any) => {
      return {
        src: apiBaseEndpoint + "/" + homePage.src,
        tag: homePage.tag !== undefined ? homePage.tag : "",
        heading: homePage.title !== undefined ? homePage.title : "",
        description:
          homePage.description !== undefined ? homePage.description : "",
      };
    });
    return (
      <Fragment>
        <div className="home-wrapper">
          <div className="root-content">
            <ArcHomePageSlider image_video_data={image_video_data} />
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArcHomePage);
