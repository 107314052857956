import React from "react";
import { MDBIcon } from "mdbreact";
import ActivityData from "../activities-json";
import { useHistory, useLocation } from "react-router-dom";
import "../activities.scss";

type BlogsProps = {
  blogs: {
    id: number;
    title: string;
    description: string;
    created_at: string;
    author: string;
  };
  months: string[];
  index: number;
  setSelectedId(index: number): void;
  status: boolean;
};

const Blogs = (props: BlogsProps) => {
  const data = props.blogs.created_at.split(" ")[0];
  const year: any = data.split("-")[0];
  const month: any = data.split("-")[1];
  const day: any = data.split("-")[2];
  let history: any = useHistory();
  let location: any = useLocation();
  let companyName = location.pathname.split("/")[1];
  const clickLocation = (id: number) => {
    history.push(`/${companyName}/activities/blogs/${id}`);
  };
  console.log(props.blogs);
  return (
    <div className="activity" key={props.index}>
      <div className="activity-title">
        <span
          className={`${props.status ? "active" : ""}`}
          id="plusminus"
          onClick={() => {
            props.setSelectedId(props.index);
          }}
        >
          <span className="activity-plus-minus">
            {props.status ? "-" : "+"}
          </span>
          <span className="activity-main-title">{props.blogs.title}</span>
        </span>
      </div>

      <div
        className={`${
          props.status ? "activity-description active" : "activity-description"
        }`}
      >
        <span className="author">
          Author : {props.blogs.author} | <MDBIcon far icon="calendar-alt" />{" "}
          {props.blogs.created_at.split(" ")[0]}
        </span>
        <div
          className="short-description"
          dangerouslySetInnerHTML={{ __html: props.blogs.description }}
        />
        <div className="button-container">
          <button
            onClick={() => {
              clickLocation(props.blogs.id);
            }}
            className="readmore"
          >
            Read more
          </button>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
