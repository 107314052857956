import React, { useState, Fragment, useEffect } from "react";
import "react-awesome-slider/dist/styles.css";
import "./slider.scss";
import YouTubePlayer from "react-player/lib/players/YouTube";

const ArcHomePageSlider = (props: any) => {
  const [play, setPlay] = useState(false);
  const [activeIndex, setActivateIndex] = useState(0);

  const _onPlay = () => {
    setPlay(true);
  };

  const _onPause = () => {
    setPlay(false);
  };
  const _prev = (event: any) => {
    _onPause();
    setActivateIndex(activeIndex > 0 ? activeIndex - 1 : 0);
  };

  const _next = (data: any) => {
    _onPause();
    setActivateIndex(activeIndex < data.length ? activeIndex + 1 : data.length);
  };

  const renderVideo = (url: string) => {
    return (
      <div className="player-main">
        <div className="player-ours" style={{ pointerEvents: "none" }}>
          <YouTubePlayer
            url={url}
            playing={play}
            controls={false}
            autoplay={false}
            className="y-player"
            id="y-player"
            youtubeConfig={{
              playerVars: {
                rel: 0,
                showinfo: 1,
                autohide: 1,
                modestbranding: 1,
                controls: 0,
                disablekb: 1,
                fs: 1,
              },
            }}
          />
        </div>
        {/* <div className="our-player-button"> */}
        <div className="our-player-play-pause">
          <button className="pause" onClick={_onPause}></button>
          <button className="play" onClick={_onPlay}>
            &#9655;
          </button>
        </div>
      </div>
    );
  };

  const renderInfo = props.image_video_data.map((all_data: any, index: any) => {
    let abc = all_data.heading != "" && all_data.desciption != "";
    console.log(abc);
    return (
      <>
        {all_data.heading != "" && all_data.desciption != "" ? (
          <div className="company-content">
            <div className="title">{all_data.heading}</div>
            <div className="description">{all_data.description}</div>
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
  });

  const renderImages = (src: string) => {
    return (
      <div className="slider-image">
        <div id="img" style={{ backgroundImage: `url(${src})` }}></div>
        <img
          src={src}
          alt="project"
          style={{
            backgroundPosition: "center",
            objectFit: "cover",
            objectPosition: "center top",
            display: "none",
          }}
        />
      </div>
    );
  };

  const autoplay = () => {
    let data = props.image_video_data.length;
  };
  autoplay();

  const data = props.image_video_data.map((all_data: any, index: any) => {
    return (
      <div className={`item`} key={index}>
        {all_data.tag === "video"
          ? renderVideo(all_data.src)
          : renderImages(all_data.src)}
      </div>
    );
  });
  const renderSliderButton = () => {
    return (
      <>
        {data.length > 1 ? (
          <div className="slider-btn-main">
            <div className="slider-buttons">
              <button
                className="previous"
                disabled={activeIndex === 0}
                onClick={_prev}
              >
                <i className="fas fa-chevron-left"></i>
              </button>
              <a
                href="#"
                className="number-text"
                style={{ textDecoration: "none", color: "#131313" }}
              >
                {(() => {
                  if (data.length < 9 && activeIndex < 9) {
                    return (
                      "0" +
                      (activeIndex + 1) +
                      " " +
                      "/" +
                      " " +
                      "0" +
                      data.length
                    );
                  } else if (data.length > 9 && activeIndex < 9) {
                    return "0" + (activeIndex + 1) + " / " + data.length;
                  } else {
                    return activeIndex + 1 + " / " + data.length;
                  }
                })()}
              </a>
              <button
                className="next"
                disabled={activeIndex >= data.length - 1}
                onClick={() => _next(data)}
              >
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
  };
  let xDown: any = null;
  let yDown: any = null;

  const getTouches = (evt: any) => {
    return (
      evt.touches || 
      evt.originalEvent.touches
    );
  };

  const handleTouchStart = (evt: any) => {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  const handleTouchMove = (evt: any) => {
    if (!xDown || !yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        if(activeIndex < data.length - 1){
          _next(data);
        }
        /* right swipe */
      } else {
        /* left swipe */
        if(activeIndex > 0){
          _prev(data);
        }
        
      }
    } 
    /* reset values */
    xDown = null;
    yDown = null;
  };
  const handleMouseDown = (evt: any) => {
    xDown = evt.clientX;
    yDown = evt.clientY;
  };

  const handleMouseMove = (evt: any) => {
    if (!xDown || !yDown) {
      return;
    }
    var xUp = evt.clientX;
    var yUp = evt.clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        if(activeIndex < data.length - 1){
          _next(data);
        }
        /* right swipe */
      } else {
        /* left swipe */
        if(activeIndex > 0){
          _prev(data);
        }
        
      }
    } 
    /* reset values */
    xDown = null;
    yDown = null;
  };
  return (
    <Fragment>
      <div className="detail-part">{renderInfo[activeIndex]}</div>
      <div className="slider-part" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove}>
        {data[activeIndex]}
        {renderSliderButton()}
      </div>
    </Fragment>
  );
};

export default ArcHomePageSlider;
