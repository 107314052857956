import React from "react";
import "./App.scss";

// import  WithSplashScreen  from "./components/hoc/withSplashScreen";
// import TitlePage from "./components/title-page/titlePage";
// import { Switch, Route } from 'react-router-dom';
import Routes from "./components/Routes";
import "bootstrap/dist/css/bootstrap.min.css";

import { RootState } from './store/root-state';
import { Dispatch } from 'redux';
import { mainDataLoad } from './store/data/data-loader';
import { Status } from './store/data/Status';
import { connect } from 'react-redux';
import TitlePage from "./components/title-page/titlePage";

const mapStateToProps = (state: RootState) => {
  return { ...state.dataModule };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadData: () => dispatch(mainDataLoad())
  };
};

type AppProps = {
  status: Status,
  loadData: () => void
}

class App extends React.Component<AppProps> {

  componentDidMount() {
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.addEventListener('keydown', event => {
      if (event.keyCode == 123) { // Prevent F12
        return false;
      } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) { // Prevent Ctrl+Shift+I        
        return false;
      }
    }
    );

    this.props.loadData();
  }

  render() {
    return (
      <main id="content">
        <TitlePage />
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
