import React, { Fragment } from "react";
import "./introduction.scss";

type IntroProps = {
  description: string
}

class IntroPage extends React.Component<IntroProps, any> {
  render() {
    // console.log(this.props);
    return (
      // <div className="container" id="introduction">
        <div className="introduction-content">
          <div className="intro-description" dangerouslySetInnerHTML={{ __html: (this.props.description) }}></div>
        </div>
      // </div>
    );
  }
}

export default IntroPage;
