import { createActions, handleAction, combineActions } from "redux-actions";
import { DataPayload } from "./data.payload";
import { MAIN_DATA_LOAD, MAIN_DATA_LOADED, MAIN_DATA_ERROR } from './data-loader.actions';
import { Status } from "./Status";

export const {mainDataLoad, mainDataLoaded, mainDataError} = createActions({
    [MAIN_DATA_LOAD]: (): DataPayload => ({status: Status.loading, data: []}),
    [MAIN_DATA_LOADED]: (data: any): DataPayload => ({status: Status.loaded, data}),
    [MAIN_DATA_ERROR]: (error: any): DataPayload => ({status: Status.error, data: error})
});

const defaultState = new DataPayload();

export const dataLoadingHandler = handleAction<DataPayload, DataPayload>(
    combineActions(mainDataLoad, mainDataLoaded, mainDataError),
    {
        next: (state, action) => {
            return action.payload;
        },
        throw: (state, action) => {
            return {status: Status.error, data: action.payload};
        }
    },
    defaultState
);
