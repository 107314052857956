import React, { Fragment } from "react";
import Layout from "../../common/layout";
import Footer from "../../footer/footer";
import Gallery from "react-grid-gallery";
import "./work.scss";
import { Link, withRouter } from "react-router-dom";
import ArcHomePageSlider from "./slider/work_slider";
// import workcategories from "./gallerydata";
import { MDBIcon } from "mdbreact";
import { apiBaseEndpoint } from "../../../constant/endpoints";
import "./slider/workSlider.scss";

class WorkDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      activeIndex: 0,
      slider: true,
      bgColor1: "black",
      bgColor2: "white",
      inlarge: false,
      totalImages: [],
    };
    this.loadDownImage = this.loadDownImage.bind(this);
    this.loadUpImage = this.loadUpImage.bind(this);
  }

  componentDidMount() {
    // const projectId = this.props.match.params.id;
    // const categoryparams = this.props.match.params.category;
    // const category =
    //   this.props.workCategories &&
    //   this.props.workCategories.find(
    //     (category) => category.title === categoryparams
    //   );
    // if(category){
    //   this.setState({
    //     images: category?.works?.slice(0, 12)
    //       ?? [],
    //     totalImages: category.works
    //       ?? [],
    //   });
    // }
    const projectId = this.props.match.params.id;
    const categoryparams = this.props.match.params.category;
    const category =
      this.props.workCategories &&
      this.props.workCategories.find(
        (category) => category.title === categoryparams
      );

    if (category) {
      const works = category.works || [];
      const work_image = works.length > 0 && works[projectId - 1];

      const filteredData = work_image
        ? this.props.company.images.filter(item => item.work_id === work_image.id)
        : [];

      this.setState({
        images: filteredData.slice(0, 12),
        totalImages: filteredData,
      })
    }
  }

  setCustomTags(i) {
    return i.tags.map((t) => {
      return (
        <div key={t.value} style={customTagStyle}>
          {t.title}
        </div>
      );
    });
  }

  styleSmall() {
    return {
      height: 142.9,
      width: 199.5,
    };
  }

  setActiveIndex = (index) => {
    this.setState({
      activeIndex: index,
    });
  };

  loadDownImage() {
    if (this.state.activeIndex >= this.state.images.length) {
      return;
    }
    let startIndex = this.state.activeIndex + 4;
    let endIndex = startIndex + 12;
    this.setState({
      images: this.state.totalImages.slice(startIndex, endIndex),
      activeIndex: this.state.activeIndex + 4,
    });
  }

  loadUpImage() {
    if (this.state.activeIndex < 4) {
      return;
    }
    let startIndex = this.state.activeIndex - 4;
    let endIndex = startIndex + 12;
    this.setState({
      images: this.state.totalImages.slice(startIndex, endIndex),
      activeIndex: this.state.activeIndex - 4,
    });
  }

  renderInfo = () => {
    const categoryparams = this.props.match.params.category;

    const projectId = this.props.match.params.id;

    const category_filtered =
      this.props.workCategories &&
      this.props.workCategories.filter(
        (category) => category["title"] === this.props.match.params.category
      );

    const category = category_filtered.length ? category_filtered[0] : {};
    const works = Object.keys(category).length != 0 ? category.works : [];
    const work_description =
      works.length > 0 && category.works[projectId - 1].description;
    return (
      <div className="work-detail">
        <div className="heading">
          Project: {works.length > 0 && category.works[projectId - 1].title}
        </div>
        <div
          className="description custom-scrollbar"
          dangerouslySetInnerHTML={{ __html: work_description }}
        ></div>
      </div>
    );
  };

  renderGallery = (images) => {
    const render_images = this.state.images.map((image) => ({
      ...image,
      src: apiBaseEndpoint + "/" + image.src,
      thumbnail: apiBaseEndpoint + "/" + image.thumbnail,
      caption: image.title,
    }));
    const noofimages = render_images.length;

    return (
      <Fragment>
        <div className="gallery-details-main" style={{gap: '20px'}}>
          <div className="gallery-desc"> {this.renderInfo()}</div>
          <div className="gallery-detail">
            {/* {render_images.length && (
              <div className="work-slider-image">
              <div id="work-img">
                <img key={render_images[0].src} src={render_images[0].src} alt="team-image" />
              </div>
            </div>
            )} */}
            
            <Gallery
              images={render_images}
              enableImageSelection={false}
              backdropClosesModal={true}
              enableLightbox={true}
              tileViewportStyle={this.styleSmall}
              thumbnailStyle={this.styleSmall}
              margin={2.5}
            /> 
            
            {noofimages > 12 ? (
              <div className="up-down-btn">
                <div className="up-down">
                  <button
                    className="up"
                    onClick={this.loadUpImage}
                    disabled={this.state.activeIndex === 0}
                  >
                    <i class="fas fa-chevron-up"></i>
                  </button>
                  <button
                    className="down"
                    onClick={this.loadDownImage}
                    disabled={
                      this.state.images.length <= 4
                        ? true
                        : this.state.activeIndex >= this.state.images.length
                        ? true
                        : false
                    }
                  >
                    <i class="fas fa-chevron-down"></i>
                  </button>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </Fragment>
    );
  };
  gridView = () => {
    this.setState({
      slider: false,
      bgColor1: "white",
      bgColor2: "black",
    });
  };

  sliderView = () => {
    this.setState({
      slider: true,
      bgColor1: "black",
      bgColor2: "white",
    });
  };

  render() {
    var images = this.state.images.map((i) => {
      i.customOverlay = (
        <div style={captionStyle}>
          <div>{i.caption}</div>
          {i.hasOwnProperty("tags") && this.setCustomTags(i)}
          <MDBIcon
            onClick={this.largescreen}
            className="inlarge-icon"
            icon="expand"
          />
        </div>
      );
      return i;
    });
    const Backgrid = () => {
      const categoryparams = this.props.match.params.category;
      let companyName = this.props.location.pathname.split("/")[1];
      return (
        <div className="back-grid">
          <div className="back">
            <Link
              to={`/${companyName}/works/${categoryparams}`}
              style={{ textDecoration: "none", color: "black" }}
            >
              Back
            </Link>
          </div>
          <div className="front">
            <div className="front-main">
              <div className="grid-container" onClick={this.gridView}>
                <span
                  className="grid-item"
                  style={{
                    backgroundColor: this.state.bgColor2,
                  }}
                ></span>
                <span
                  className="grid-item"
                  style={{
                    backgroundColor: this.state.bgColor2,
                  }}
                ></span>
                <span
                  className="grid-item"
                  style={{
                    backgroundColor: this.state.bgColor2,
                  }}
                ></span>
                <span
                  className="grid-item"
                  style={{
                    backgroundColor: this.state.bgColor2,
                  }}
                ></span>
              </div>
              <div className="slider-container">
                <div className="ltoogle-bar">
                  <div
                    className="rectangle-toggle-box"
                    style={{ backgroundColor: this.state.bgColor1 }}
                    onClick={this.sliderView}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };
    
    return (
      <Fragment>
        <div className="main-container">
          <Backgrid />

          {this.state.slider === true ? (
            <Fragment>
            <div className="gallery-details-main" style={{gap: '20px'}}>
              <div className="gallery-desc"> {this.renderInfo()}</div>
              <div className="gallery-detail">
              <div className="work-root-content">
              <ArcHomePageSlider image_video_data={images} display_info={false}/>
            </div>
              </div>
            </div>
          </Fragment>
          ) : (
            <div>{this.renderGallery(images)}</div>
          )}
          {/* <Menu/> */}
          {/* {this.renderSwitch(images)} */}
        </div>
        <Footer />
      </Fragment>
    );
  }
}

const captionStyle = {
  backgroundColor: "transparent",
  maxHeight: "240px",
  overflow: "hidden",
  position: "absolute",
  bottom: "50px",
  width: "100%",
  color: "white",
  padding: "2px",
  fontSize: "90%",
  textAlign: "center",
  zIndex: "9999999",
};

const customTagStyle = {
  wordWrap: "break-word",
  display: "inline-block",
  backgroundColor: "white",
  height: "auto",
  fontSize: "75%",
  fontWeight: "600",
  lineHeight: "1",
  padding: ".2em .6em .3em",
  borderRadius: ".25em",
  color: "black",
  verticalAlign: "baseline",
  margin: "2px",
};

WorkDetail.defaultProps = {
  images: [],
};

export default withRouter(WorkDetail);
