import React, { Fragment, useState } from "react";
import Layout from "../../common/layout";
import TeamPage from "./team/team";
import IntroPage from "./intro/introduction";
import Footer from "../../footer/footer";
import AboutData from "./aboutus.json";
import { useParams } from "react-router";
import "./intro/introduction.scss";

type ActivityProps = {
  introduction : string,
  team : string
}

const AboutUs = (props: any) => {
  const [active, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState("intro");

  // const renderSwitch = (activeTab: string) => { last comment
  //   // debugger
  //   switch (activeTab) {
  //     case "team":
  //       return <TeamPage image={props.team} />;
  //     case "intro":
  //       return <IntroPage description={props.introduction} />;
  //     default:
  //       return "";
  //   }
  // };
  // const { match, location } = props;
  // debugger
  // const { page } = useParams(); last comment
  return (
    <Fragment>
      {/* {renderSwitch(page)} last comment*/ }
      <div className="about-wrapper">
          <div className="about-gallery-main">
            {/* <div className="gallery-info"></div> */}
            <div className="about-gallery">
              <div className="about-gallery-info">
                <div className="about-info">
                  <IntroPage description={props.introduction} />
                </div>
              </div>
              <div className="about-gallery-info-images">
                <TeamPage image={props.team} />
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default AboutUs;
