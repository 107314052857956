import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { MDBIcon } from "mdbreact";
import "./layout.scss";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";

const Layout = (props) => {
  // let { companyName } = useParams(); // use for 2 or more comp
  const companyName = 'arc'; //remove for 2 or more comp
  let history = useHistory();

  const companies = useSelector((state) => state.dataModule.data);

  const [active, setActive] = useState(true);

  const toogleActive = () => {
    setActive(!active);
  };

  const renderSocialMedia = (contact) => {
    return (
      <ul id="social-media">
        {/* <div> */}
        
          {contact && contact.twitter_url ? (
            <li className="s-icons">
            <span id="icon">
              <a target="_blank" href={contact && contact.twitter_url}>
                <MDBIcon fab icon="twitter" />
              </a>
            </span>
            </li>
          ) : null}
        
        
          {contact && contact.fb_url ? (
            <li className="s-icons">
            <span id="icon">
              <a target="_blank" href={contact && contact.fb_url}>
                <MDBIcon fab icon="facebook-f" />
              </a>
            </span>
            </li>
          ) : null}
            
          {contact && contact.linked_in_url ? (
            <li className="s-icons">
 
            <span id="icon">
              <a target="_blank" href={contact && contact.linked_in_url}>
                <MDBIcon fab icon="linkedin-in" />
              </a>
            </span>
            </li>
          ) : null}
          {contact && contact.youtube_url ? (
            <li className="s-icons">
 
            <span id="icon">
              <a target="_blank" href={contact && contact.youtube_url}>
                <MDBIcon fab icon="youtube" />
              </a>
            </span>
            </li>
          ) : null}
          {contact && contact.insta_url ? (
            <li className="s-icons">
 
            <span id="icon">
              <a target="_blank" href={contact && contact.insta_url}>
                <MDBIcon fab icon="instagram" />
              </a>
            </span>
            </li>
          ) : null}
          
        {/* </div> */}
      </ul>
    );
  };

  function handleClick(companyName) {
    history.push(`${companyName}`);
  }

  const renderArc = (companies) => {
    return (
      <div className="company-logo">
        {/* <Link to={`${(companies && companies.length > 0) && companies[0].name.toLowerCase()}`}> */}
        {/* <a onClick={() => this.handleClick(`${(companies && companies.length > 0) ?  companies[0].name.toLowerCase(): 'arc'}`)}> */}
        {/* <a
          href={`/${
            companies && companies.length > 0 && companies[0].name.toLowerCase()
          }`}
        > */}
          <a
          href={`/`}
        >
          <img
            alt="MDB React Logo"
            className="img-fluid"
            // src={process.env.PUBLIC_URL + "image/arc_logo.png"} use for 2 or more comp 
            src={process.env.PUBLIC_URL + "/image/arc_logo.png"} 
          />
        </a>
        {/* </Link> */}

        {renderSocialMedia(props.contact)}
        {/* <div className="toogle-company"> last comment*/} 
          {/* <Link
            to={`${(companies && companies.length > 0) &&  companies[1].name.toLowerCase()}`}
            style={{ textDecoration: "none" }}
          > */}
          {/* <a onClick={() => this.handleClick(`${(companies && companies.length > 0) ?  companies[1].name.toLowerCase(): 'beam'}`)}> */}
          {/* Last comment */}
          {/* <a
            href={`/${
              companies &&
              companies.length > 0 &&
              companies[1].name.toLowerCase()
            }`}
          >
            <div className="toogle-bar"></div>
            <div className="toogle-text">
              Go to{" "}
              <span id="company-name">
                {companies && companies.length > 0
                  ? companies[1].name.toUpperCase()
                  : ""}
              </span>
            </div>
          </a> last comment*/}
          {/* </Link> */}
        {/* </div> last comment*/}
      </div>
    );
  };

  const renderBeam = (companies) => {
    return (
      <div className="company-logo">
        {/* <Link to={`${(companies && companies.length > 0) &&  companies[1].name.toLowerCase()}`}> */}
        {/* <div onClick={() => this.handleClick(`${(companies && companies.length > 0) ? companies[1].name.toLowerCase() : ''}`)}> */}
        {/* <a last comment
          href={`/${
            companies && companies.length > 0 && companies[1].name.toLowerCase()
          }`}
        >
          <img
            alt="MDB React Logo"
            className="img-fluid"
            src={process.env.PUBLIC_URL + "/image/beam_logo.png"}
          /> last comment*/}
          {/* </div>
           */}
        {/* </a> last comment*/}
        {/* </Link> */}
        {/* <div className="toogle-company"> last comment */}
          {/* <Link
            to={`${(companies && companies.length > 0) && companies[0].name.toLowerCase()}`}
            style={{ textDecoration: "none" }}
          > */}
          {/* <div onClick={() => this.handleClick(`${(companies && companies.length > 0) ?  companies[0].name.toLowerCase(): ''}`)}> */}
          {/* last comment
          <a
            href={`/${
              companies &&
              companies.length > 0 &&
              companies[0].name.toLowerCase()
            }`}
          >
            <div className="toogle-bar"></div>
            <div className="toogle-text">
              Go to{" "}
              <span id="company-name">
                {companies && companies.length > 0 ? companies[0].name : ""}
              </span>
            </div> last comment*/}
            {/* </div>
             */}
          {/* </a> last comment*/}
          {/* </Link> */}
        {/* </div> ;last comment*/}
      </div>
    );
  };

  const company = companies && companies.length > 0 && companies[0];
  // console.log(company);
  return (
    <Fragment>
      <div className="header-wrapper">
        {company && company.name.toLowerCase() === companyName 
          ? renderArc(companies)
          : renderBeam(companies)}
      </div>
    </Fragment>
  );
};

export default Layout;
