import React from "react";
import { MDBIcon } from "mdbreact";
import ActivityData from "../activities-json";
import { useHistory, useLocation } from "react-router-dom";
import "../activities.scss";

type CareerProps = {
  news: {
    id: number;
    title: string;
    description: string;
    created_at: string;
  };
  months: string[];
  index: number;
  setSelectedId(index: number): void;
  status: boolean;
};

const News = (props: CareerProps) => {
  const data = props.news.created_at.split(" ")[0];
  const year: any = data.split("-")[0];
  const month: any = data.split("-")[1];
  const day: any = data.split("-")[2];
  let history: any = useHistory();
  let location: any = useLocation();
  let companyName = location.pathname.split("/")[1];

  const clickLocation = (id: number) => {
    history.push(`/${companyName}/activities/news/${id}`);
  };
  return (
    <div className="activity" key={props.index}>
      <div className="activity-title">
        <span
          className={`${props.status ? "active" : ""}`}
          id="plusminus"
          onClick={() => {
            props.setSelectedId(props.index);
          }}
        >
          <span className="activity-plus-minus">
            {props.status ? "-" : "+"}
          </span>
          <span className="activity-main-title">{props.news.title}</span>
        </span>
      </div>

      <div
        className={`${
          props.status ? "activity-description active" : "activity-description"
        }`}
      >
        <span className="date">
          <MDBIcon far icon="calendar-alt" />{" "}
          {props.news.created_at.split(" ")[0]}
        </span>
        <div
          className="short-description"
          dangerouslySetInnerHTML={{ __html: props.news.description }}
        />
        <button
          onClick={() => {
            clickLocation(props.news.id);
          }}
          className="readmore"
        >
          Read more
        </button>
      </div>
    </div>
  );
};

export default News;
