import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { mainDataError, mainDataLoaded } from './data-loader';
import { MAIN_DATA_LOAD } from './data-loader.actions';
import { apiEndPoint } from '../../constant/endpoints';

export const dataLoaderEpic: Epic = action$ => action$.pipe(
    ofType(MAIN_DATA_LOAD),
    mergeMap(action =>
        ajax.getJSON<any>(`${apiEndPoint}/company`).pipe(
            map(response => mainDataLoaded(response)),
            catchError(error => of(mainDataError(error)))
        )
    ),
    catchError(error => {
        console.log(error);
        return of(mainDataError(error));
    })
);
