import { apiBaseEndpoint, apiEndPoint } from "../constant/endpoints";

let baseURL = apiEndPoint; // dev server

export const postMailToCompany = (data: any) => {
  return fetch(`${baseURL}/contact/mail`, {
    method: "POST",
    body: data,
  });
};
