import React, { Fragment, useState } from "react";
import props from "../activities-json";
import Blogs from "./blogs";
import "../activities.scss";
import NoResult from "../no_result/no_result";

type BlogListProps = {
  blogs: [];
  months: string[];
};

function BlogsList(props: BlogListProps) {
  const [selectedId, setSelectedId] = useState(0);

  const renderBlogList = () => {
    console.log(selectedId);
    return (
      <div className="container" id="introduction">
        <div className="activities" id="blogs">
          {props.blogs &&
            props.blogs.map((blog, index) => {
              const status = selectedId === index;
              console.log(status);
              return (
                <Blogs
                  index={index}
                  blogs={blog}
                  status={status}
                  setSelectedId={setSelectedId}
                  months={props.months}
                />
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {props.blogs && props.blogs.length > 0 ? (
        renderBlogList()
      ) : (
        <NoResult title="Blogs" />
      )}
    </Fragment>
  );
}

export default BlogsList;
