import React, { Fragment, useState, useEffect } from "react";
import "./menu.scss";
import Burger from "react-css-burger";
import { NavLink, useParams, useLocation } from "react-router-dom";
import workcategories from "../work/gallerydata";

const MenuBar = (props: any) => {
  const [active, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [activeAbout, setActiveAbout] = useState(false);
  const [activeWorks, setActiveWorks] = useState(false);
  const [activeActivities, setActiveActivities] = useState(false);
  const [showBreadcrumb, setShowBreadcrumb] = useState(false);
  let location: any = useLocation();
  let submenus: any = location.pathname.split("/")[3];  
  let mainmenus: any = location.pathname.split("/")[2]; 
  // let companyName = location.pathname.split("/")[1]; use for 2 or more comp
  let companyName = 'arc'; //remove for 2 or more comp

  const mainmenu = () => {
    switch (mainmenus) {
      case "arc":
        return null;
      case "eng":
        return null;
      default:
        return mainmenus;
    }
  };
  const submenu = () => {
    switch (submenus) {
      case "arc":
        return null;
      case "eng":
        return null;
      case "contact":
        return null;
      default:
        return submenus;
    }
  };

  function onMenuListClick(tab: string) {
    setActive(!active);
    setActiveTab(tab);
  }

  const renderContactMenu = () => {
    return (
      <div
        className="contact-menu"
        id="sub-menu"
        style={{ fontSize: "1.9em" }}
        onClick={() => {
          setActiveWorks(!activeWorks);
          setActiveAbout(false);
        }}
      >
        CONTACT
      </div>
    );
  };

  const RenderAboutMenu = () => {
    return (
      <div className="about-menu">
        <div className="list">
          <NavLink
            exact
            to={`/${companyName}/about/intro`}
            style={{
              fontSize: "0.9rem",
              textDecoration: "none",
              color: "black",
              outline: "none",
              margin: "0",
            }}
            onClick={() => {
              setActive(!active);
              setShowBreadcrumb(!showBreadcrumb);
            }}
            activeClassName="menu-active"
          >
            Introduction
          </NavLink>

          <NavLink
            exact
            to={`/${companyName}/about/team`}
            style={{
              fontSize: "0.9rem",
              textDecoration: "none",
              color: "black",
              outline: "none",
              margin: "0",
            }}
            onClick={() => {
              setActive(!active);
              setShowBreadcrumb(!showBreadcrumb);
            }}
            activeClassName="menu-active"
          >
            Team
          </NavLink>
        </div>
      </div>
    );
  };

  const RenderActivities = () => {
    return (
      <div className="about-menu">
        <div className="list">
          <NavLink
            exact
            to={`/${companyName}/activities/career`}
            style={{
              fontSize: "0.9rem",
              textDecoration: "none",
              color: "black",
              outline: "none",
              margin: "0",
            }}
            onClick={() => {
              setActive(!active);
              setShowBreadcrumb(!showBreadcrumb);
            }}
            activeClassName="menu-active"
          >
            Career
          </NavLink>
          <NavLink
            exact
            to={`/${companyName}/activities/blogs`}
            style={{
              fontSize: "0.9rem",
              textDecoration: "none",
              color: "black",
              outline: "none",
              margin: "0",
            }}
            onClick={() => {
              setActive(!active);
              setShowBreadcrumb(!showBreadcrumb);
            }}
            activeClassName="menu-active"
          >
            Blogs
          </NavLink>
          <NavLink
            exact
            to={`/${companyName}/activities/news`}
            style={{
              fontSize: "0.9rem",
              textDecoration: "none",
              color: "black",
              outline: "none",
              margin: "0",
            }}
            onClick={() => {
              setActive(!active);
              setShowBreadcrumb(!showBreadcrumb);
            }}
            activeClassName="menu-active"
          >
            News
          </NavLink>
        </div>
      </div>
    );
  };
  const RenderWorkMenu = () => {
    const workcategorylist = props.workCategories?.map(
      (category: any, index: any) => {
        return (
          <NavLink
            key={category.id}
            to={`/${companyName}/works/${category.title}`}
            style={{
              fontSize: "0.9rem",
              textDecoration: "none",
              color: "black",
            }}
            onClick={() => {
              setActive(!active);
              setShowBreadcrumb(!showBreadcrumb);
            }}
            activeClassName="menu-active"
          >
            {category.title}
          </NavLink>
        );
      }
    );
    return <div className="work-menu">{workcategorylist}</div>;
  };

  const renderTopMenuList = () => {
    return (
      <div className="menu-item">
        <a
          id="main-menu"
          onClick={() => {
            setActiveActivities(!activeActivities);
            setActiveWorks(false);
            setActiveAbout(false);
          }}
          style={{
            textDecoration: "none",
            color: "black",
            outline: "none",
          }}
        >
          {activeActivities ? (
            <span id="plus-minus"> - </span>
          ) : (
            <span id="plus-minus"> + </span>
          )}
          ACTIVITIES
        </a>
        {activeActivities ? ( 
          <div id="transit">
            <RenderActivities />
          </div>
        ) : (
          <div id="transit"></div>
        )}
        {/* <a    last comment
          id="main-menu"
          onClick={() => {
            setActiveAbout(!activeAbout);
            setActiveWorks(false);
            setActiveActivities(false);
          }}
          style={{
            textDecoration: "none",
            color: "black",
            outline: "none",
          }}
        >
          {activeAbout ? (
            <span id="plus-minus"> - </span>
          ) : (
            <span id="plus-minus"> + </span>
          )}
          ABOUT
        </a>
       
        {activeAbout ? (
          <div id="transit">
            <RenderAboutMenu />
          </div>
        ) : (
          <div id="transit"></div>
        )} */}
        <NavLink
          id="main-menu"
          exact
          to={`/${companyName}/about`}
          style={{
            textDecoration: "none",
            color: "black",
            outline: "none",
          }}
          onClick={() => {
            // setActiveWorks(!activeWorks);
            setActiveAbout(false);
            setActive(!active);
            setShowBreadcrumb(!showBreadcrumb);
          }}
        >
          ABOUT
        </NavLink>
        <a
          id="main-menu"
          onClick={() => {
            setActiveWorks(!activeWorks);
            setActiveActivities(false);
            setActiveAbout(false);
          }}
          style={{
            textDecoration: "none",
            color: "black",
            outline: "none",
            margin: "0px",
            paddingBottom: "5px",
          }}
        >
          {activeWorks ? (
            <span id="plus-minus"> - </span>
          ) : (
            <span id="plus-minus"> + </span>
          )}
          WORKS <br />
        </a>

        {activeWorks ? <RenderWorkMenu /> : ""}
        <NavLink
          id="main-menu"
          exact
          to={`/${companyName}/contact`}
          style={{
            textDecoration: "none",
            color: "black",
            paddingBottom: "5px",
            outline: "none",
          }}
          onClick={() => {
            // setActiveWorks(!activeWorks);
            setActiveAbout(false);
            setActive(!active);
            setShowBreadcrumb(!showBreadcrumb);
          }}
        >
          CONTACT
        </NavLink>
      </div>
    );
  };

  const renderSwitch = (activeTab: string) => {
    switch (activeTab) {
      case "":
        return active ? renderTopMenuList() : null;
      default:
        return "No match";
    }
  };

  return (
    <Fragment>
      <div className="menu-main">
        <div className="menu">
          <div className="ham-menu">
            <Burger
              onClick={() => {
                setActive(!active);
                setActiveTab("");
                setShowBreadcrumb(!showBreadcrumb);
              }}
              active={active}
              style={{ outline: "none" }}
            />
          </div>
          {showBreadcrumb ? (
            renderSwitch(activeTab)
          ) : (
            <>
              <span className="location">{mainmenu()}</span>
              <span className="submenu">{submenu()}</span>
            </>
          )}
          <div className="copyright" title="2020 All rights reserved. ARC">
            &#169;
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MenuBar;
