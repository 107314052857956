import React, { useState, Fragment, useEffect } from "react";
import "react-awesome-slider/dist/styles.css";
import "./workSlider.scss";
import YouTubePlayer from "react-player/lib/players/YouTube";
// import Footer from "../../../footer/footer.jsx";
import { apiBaseEndpoint } from "../../../../constant/endpoints";

const WorkSlider = (props: any) => {
  // const [activated, setActivated] = useState('');
  const [play, setPlay] = useState(true);
  const [activeIndex, setActivateIndex] = useState(0);

  // const _toogleFullScreen = (event: any) => {
  //   const element = document.getElementById('y-player');
  //   if (element !== null && element.requestFullscreen) {
  //     element.requestFullscreen();
  //   }
  // }

  const _onPlay = () => {
    setPlay(true);
  };

  const _onPause = () => {
    setPlay(false);
  };

  // const _onReady = (event: any) => {
  //   event.target.pauseVideo();
  // }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const dataLength = props.image_video_data.length - 1;
  //     return setActivateIndex((activeIndex) =>
  //       activeIndex < dataLength ? activeIndex + 1 : activeIndex % dataLength
  //     );
  //   }, 12000);
  //   return () => clearInterval(interval);
  // }, [activeIndex]);

  const _prev = (event: any) => {
    _onPause();
    setActivateIndex(activeIndex > 0 ? activeIndex - 1 : 0);
  };

  const _next = (data: any) => {
    _onPause();
    setActivateIndex(activeIndex < data.length ? activeIndex + 1 : data.length);
  };

  const renderVideo = (url: string) => {
    return (
      <div className="work-player-main">
        <div className="work-player-ours" style={{ pointerEvents: "none" }}>
          <YouTubePlayer
            url={url}
            playing={play}
            controls={false}
            autoplay={false}
            className="work-y-player"
            id="work-y-player"
            youtubeConfig={{
              playerVars: {
                rel: 0,
                showinfo: 1,
                autohide: 1,
                modestbranding: 1,
                controls: 0,
                disablekb: 1,
                fs: 1,
              },
            }}
          />
        </div>
        {/* <div className="our-player-button"> */}
        <div className="work-our-player-play-pause">
          <button className="work-pause" onClick={_onPause}></button>
          <button className="work-play" onClick={_onPlay}>
            &#9655;
          </button>
        </div>
        {/* <div className="our-player-fullscreen">
          <button className="fullScreen" onClick={_toogleFullScreen}>FullScreen</button>

        </div> */}
      </div>
    );

    // </div>
  };

  const renderInfo = props.image_video_data.map((all_data: any, index: any) => {
    return (
      <div className="work-company-content">
        <div className="work-title">{all_data.heading}</div>
        <div className="description" dangerouslySetInnerHTML={{ __html: all_data.description }}></div>
      </div>
    );
  });

  const renderImages = (src: string) => {
    return (
      <div className="work-slider-image">
        <div id="work-img">
          <img src={apiBaseEndpoint + "/" + src} alt="project" />
        </div>
      </div>
    );
  };

  const autoplay = () => {
    let data = props.image_video_data.length;
  };
  autoplay();
  const data = props.image_video_data.map((all_data: any, index: any) => {
    // const activeNumber = 0;
    // const activeStatus = activeNumber === index ? 'active' : '';
    return (
      <div className={`work-item`} key={index}>
        {all_data.tag === "video"
          ? renderVideo(all_data.src)
          : renderImages(all_data.src)}
      </div>
    );
  });

  const renderSliderButton = () => {
    return (
      <div className="work-slider-btn-main">
        <div className="work-slider-buttons">
          <button
            className="previous"
            disabled={activeIndex === 0}
            onClick={_prev}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <a
            href="#"
            className="number-text"
            style={{ textDecoration: "none", color: "#131313" }}
          >
            {(() => {
              if (data.length < 9 && activeIndex < 9) {
                return (
                  "0" + (activeIndex + 1) + " " + "/" + " " + "0" + data.length
                );
              } else if (data.length > 9 && activeIndex < 9) {
                return "0" + (activeIndex + 1) + " / " + data.length;
              } else {
                return activeIndex + 1 + " / " + data.length;
              }
            })()}
          </a>
          <button
            className="next"
            disabled={activeIndex >= data.length - 1}
            onClick={() => _next(data)}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    );
  };
  
  return (
    <Fragment>
      {props.display_info && <div className="work-detail-part">{renderInfo[activeIndex]}</div>}
      <div className="work-slider-part">
        {data[activeIndex]}
        {renderSliderButton()}
      </div>
    </Fragment>
  );
};

export default WorkSlider;
