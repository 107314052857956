import React, { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../activities.scss";
import { MDBIcon } from "mdbreact";
import NoResult from "../../no_result/no_result";

type CareerDetailProps = {
  career: {
    id: number;
    title: string;
    description: string;
    created_at: string;
  };
  months: string[];
};

const CareerDetails = (props: CareerDetailProps) => {
  let history: any = useHistory();
  let location: any = useLocation();
  let companyName = location.pathname.split("/")[1];
  console.log(props.career);

  const clickLocation = () => {
    history.push(`/${companyName}/activities/career`);
  };
  return (
    <Fragment>
      {props.career ? (
        <div className="activity" key={props.career.id}>
          <div className="goback">
            <span onClick={() => clickLocation()}>back</span>
          </div>
          <div className="activity-title details">
            <span>{props.career.title}</span>
            <button
              onClick={() => {
                history.push(`/${companyName}/contact`);
              }}
              className="contactus"
            >
              Contact Us
            </button>
          </div>
          <div className="below-title">
            <span className="date">
              <MDBIcon far icon="calendar-alt" />{" "}
              {props.career.created_at.split(" ")[0]}
            </span>
          </div>
          <div className="activity-description active">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: props.career.description }}
            />
          </div>
        </div>
      ) : (
        <NoResult title="data" />
      )}
    </Fragment>
  );
};

export default CareerDetails;
