import React, { Fragment, useState } from "react";
import props from "../activities-json";
import "../activities.scss";
import NoResult from "../no_result/no_result";
import News from "./news";

type NewsListProps = {
  news: [];
  months: string[];
};

const NewsList = (props: NewsListProps) => {
  const [selectedId, setSelectedId] = useState(0);
  const renderNewsList = () => {
    // debugger;
    return (
      <div className="container" id="introduction">
        <div className="activities" id="blogs">
          {props.news &&
            props.news.map((news, index) => {
              const status = selectedId === index;
              return (
                <News
                  index={index}
                  news={news}
                  status={status}
                  setSelectedId={setSelectedId}
                  months={props.months}
                />
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {props.news && props.news.length > 0 ? (
        renderNewsList()
      ) : (
        <NoResult title="News" />
      )}
    </Fragment>
  );
};

export default NewsList;
