import React from "react";
import { useLocation } from "react-router-dom";
import ActivityData from "../../activities-json";
import CareerDetails from "./careerDetails";
import "../../activities.scss";

type CareerListProps = {
  careers: [];
  months: string[];
};

const SingleCareer = (props: CareerListProps) => {
  let location: any = useLocation();
  let careerId = location.pathname.split("/")[4];

  const Data: any =
    props.careers.length > 0 &&
    props.careers.find((filterData: any) => {
      return filterData.id === careerId;
    });
  return (
    <div>
      <div className="container" id="introduction-details">
        <div className="activities" id="blogs">
          <CareerDetails career={Data} months={props.months} />
        </div>
      </div>
    </div>
  );
};

export default SingleCareer;
