import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { companyInfoError, companyInfoLoaded } from './company-info';
import { COMPANY_INFO_LOAD } from './company-info.actions';
//import content from '../../mock/content.json';
import { apiEndPoint } from '../../../constant/endpoints';

export const companyInfoEpic: Epic = action$ => action$.pipe(
    ofType(COMPANY_INFO_LOAD),
    mergeMap(action => 
        // of(companyInfoLoaded(content.find(x => x.id === action.payload.data.id)))
        ajax.getJSON<any>(`${apiEndPoint}/company/${action.payload.data.id}`).pipe(
            map(response => companyInfoLoaded(response)),
            catchError(error => of(companyInfoError(error)))
        )
    ),
    catchError(error => {
        console.log(error);
        return of(companyInfoError(error));
    }) 
);
