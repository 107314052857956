import React, { useState } from "react";
import "./titlePage.scss";
import { Link, Route, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { RootState } from "../../store/root-state";
import { mainDataLoad } from "../../store/data/data-loader";
import { connect } from "react-redux";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import HomePage from "../arc/homepage/homepage";
import Routes from "../Routes";
import LandingPage from "../landingPage/landingPage";
import { thisExpression } from "@babel/types";

const mapStateToProps = (state: RootState) => {
  const { data } = state.dataModule;
  return {
    data: [...(data || [])],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadData: () => dispatch(mainDataLoad()),
  };
};

class MainPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      arcActive: "active",
      engActive: "",
    };
  }

  componentDidMount() {
  setTimeout(() => {
    const arcCompany = this.props.companies[0];
    if (arcCompany) {
      // window.location.href = `/${
      //   arcCompany !== undefined && arcCompany.name.toLowerCase()
      // }`;
      // this.props.history.push(`/${
      //   this.props.companies[0] != undefined &&
      //   this.props.companies[0].name.toLowerCase()}); // use for 2 or more comp
        this.props.history.push(`/`);
        this.props.toogleShowPage(arcCompany.name)
      }
  }, 2000);
}

  render() {
    return (
      <div className="title-page">
        {/* <div className={`architecture ${this.state.arcActive}`}>
          <Link
            to={`/${
              this.props.companies[0] != undefined &&
              this.props.companies[0].name.toLowerCase()
            }`}
          >
            <div
              className="architecture-page"
              onMouseEnter={() => {
                this.setState({ arcActive: "active" });
              }}
              onMouseLeave={() => {
                this.setState({ arcActive: "" });
              }}
              onClick={() => {
                console.log("Architecture clicked");
                this.props.toogleShowPage(this.props.companies[0].name);
              }}
            >
              {this.state.arcActive ? (
                <div className="img-overlay">
                  <img
                    className="title-image"
                    src={process.env.PUBLIC_URL + "/image/arc_logo.png"}
                    alt="Website logo"
                  ></img>
                </div>
                ) : (
                <span>ARCHITECTURE</span>
              )} 
            </div>
          </Link> 
        </div> */}

        <div className={`architecture ${this.state.arcActive}`}>
            <div>
                <div className="img-overlay">
                  <img
                    className="title-image"
                    src={process.env.PUBLIC_URL + "/image/arc_logo.png"}
                    alt="Website logo"
                  ></img>
                </div>
            </div>
        </div>
        {/* <div id="divider" className="divider"></div>

        <div className={`engineering ${this.state.engActive}`}>
          <Link
            to={`/${
              this.props.companies[1] != undefined &&
              this.props.companies[1].name.toLowerCase()
            }`}
          >
            <div
              className="engineering-page"
              onMouseEnter={() => {
                this.setState({ engActive: "active" });
              }}
              onMouseLeave={() => {
                this.setState({ engActive: "" });
              }}
              onClick={() => {
                console.log("Engineering clicked");
                this.props.toogleShowPage(this.props.companies[1].name);
              }}
            >
              {this.state.engActive ? (
                <div className="img-overlay">
                  <img
                    className="title-image"
                    src={process.env.PUBLIC_URL + "/image/beam_logo.png"}
                    alt="Website logo"
                  ></img>
                </div>
              ) : (
                <span>ENGINEERING</span>
              )}
            </div>
          </Link>
        </div> */}
      </div>
    );
  }
}

class TitlePage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showPage: false,
      activePage: "",
    };
    this.toogleShowPage = this.toogleShowPage.bind(this);

  }

  componentDidMount() {
    let comp = this.props.location.pathname.split("/")[1];
    if (this.props.location.pathname.split("/")[1] !== "") {
      this.toogleShowPage(comp);
      this.props.loadData();
    }
  }
  toogleShowPage = (activePage: string) => {
    this.setState({
      showPage: true,
      activePage: activePage,
    });
  };
  render() {
    const companies = this.props.data as any[];
    return (
      <div>
        {this.state.showPage ? (
          // ? <div> <LandingPage activePage={this.state.activePage}/></div>
          <Routes {...this.props} />
        ) : (
          <div>
            <MainPage
              toogleShowPage={this.toogleShowPage}
              companies={companies}
              history={this.props.history}
            />{" "}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TitlePage));
