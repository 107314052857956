import React, { Fragment, useState } from "react";
import Layout from "../../common/layout";
import SingleBlogs from "./blogs/single/singleBlog";
import SingleCareer from "./career/single/singleCareer";
import SingleNews from "./news/single/singleNews";
import Footer from "../../footer/footer";
import { useParams } from "react-router";

type ActivitiesProps = {
  blogs: [];
  careers: [];
  news: [];
  // match: any;
};
const Activity = (props: ActivitiesProps) => {
  var months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const renderSwitch = (activeTab: string) => {
    switch (activeTab) {
      case "career":
        return <SingleCareer careers={props.careers} months={months} />;
      case "blogs":
        return <SingleBlogs blogs={props.blogs} months={months} />;
      case "news":
        return <SingleNews newsList={props.news} months={months} />;
      default:
        return "";
    }
  };
  // const { match } = props;
  let { page } = useParams();
  return (
    <Fragment>
      {renderSwitch(page)}
      <Footer />
    </Fragment>
  );
};

export default Activity;
