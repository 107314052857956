import React, { Fragment, useState } from "react";
import search from "../../../../assets/icons/search.png";
import "../activities.scss";

type NoResultProps = {
  title: string;
};

const NoResult = (props: NoResultProps) => {
  const renderNoResult = () => {
    return (
      <div className="container" id="introduction">
        <div className="activities" id="blogs">
          <div className="not-found">
            <div className="not-found-content">
              <div>
                <img src={search} className="search-icon" alt="search-icon" />
              </div>
              <div className="no-text">No {props.title} found !</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <Fragment>{renderNoResult()}</Fragment>;
};

export default NoResult;
