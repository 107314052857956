import React, { Component, Fragment, CSSProperties } from "react";
import Gallery from "react-grid-gallery";
import Layout from "../../common/layout";
import Footer from "../../footer/footer";
import "./work.scss";
import { MDBIcon } from "mdbreact";
import { withRouter } from "react-router";
import { apiEndPoint, apiBaseEndpoint } from "../../../constant/endpoints";
// import { CSSProperties } from "jss/css";

class WorkPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      images: [],
      activeIndex: 0,
      active: false,
      totalImages: [],
    };
    this.loadDownImage = this.loadDownImage.bind(this);
    this.loadUpImage = this.loadUpImage.bind(this);
    this.imageNavigate = this.imageNavigate.bind(this);
  }

  componentDidMount() {
    const categoryparams = this.props.match.params.category;

    const category =
      this.props.workCategories?.find(
        (category: any) => category["title"] === categoryparams
      );
    if (category) {
      this.setState({
        images:  category?.works??[],
        totalImages: category?.works?.length??0,
      });
    }
  }

  setCustomTags(i: any) {
    return i.tags.map((t: any) => {
      return (
        <div key={t.value} style={customTagStyle}>
          {" "}
          {t.title}{" "}
        </div>
      );
    });
  }

  styleSmall() {
    return {
      height: 142.9,
      width: 199.5,
    };
  }

  imageNavigate = (record: any) => {
    const categoryparams = this.props.match.params.category;
    let companyName = this.props.location.pathname.split("/")[1];
    var path =
      `/${companyName}/works/${categoryparams}/work-detail/` + (record + 1);
    this.props.history.push(path);
  };

  loadDownImage() {
    if (this.state.activeIndex >= this.state.images.length) {
      return;
    }
    let startIndex = this.state.activeIndex + 4;
    let endIndex = startIndex + 12;
    this.setState({
      images: this.state.totalImages.slice(startIndex, endIndex),
      activeIndex: this.state.activeIndex + 4,
    });
  }

  loadUpImage() {
    if (this.state.activeIndex < 4) {
      return;
    }
    let startIndex = this.state.activeIndex - 4;
    let endIndex = startIndex + 12;
    this.setState({
      images: this.state.totalImages.slice(startIndex, endIndex),
      activeIndex: this.state.activeIndex - 4,
    });
  }
  down() {
    return true;
  }

  render() {

    const category_filtered =
      this.props.workCategories &&
      this.props.workCategories.filter(
        (category: any) =>
          category["title"] === this.props.match.params.category
      );
    const category = category_filtered ? category_filtered[0] : [];
    const works: [] =
      category && category.works
        ? category.works.map((project: any) => ({
            ...project,
            src: apiBaseEndpoint + "/" + project.src,
            thumbnail: apiBaseEndpoint + "/" + project.thumbnail,
            caption: project.title,
            customOverlay: (
              <div style={captionStyle}>
                <div>{project.title}</div>
                {project.hasOwnProperty("tags") && this.setCustomTags(project)}
              </div>
            ),
          }))
        : [];
        
    return (
      <Fragment>
        <div className="work-wrapper">
          <div className="gallery-main">
            {/* <div className="gallery-info"></div> */}
            <div className="gallery">
              <div className="gallery-info">
                <div className="work-info">
                  <h4>{category && category.title}</h4>
                  <p>{category && category.description}</p>
                </div>
              </div>
              <div className="gallery-info-images">
                <Gallery
                  images={works}
                  enableImageSelection={false}
                  backdropClosesModal={true}
                  tileViewportStyle={this.styleSmall}
                  thumbnailStyle={this.styleSmall}
                  onClickThumbnail={this.imageNavigate}
                  margin={2.5}
                />
                {this.state.images.length > 12 ? (
                  <div className="up-down-btn">
                    <div className="up-down">
                      <button
                        className="up"
                        onClick={this.loadUpImage}
                        disabled={this.state.activeIndex === 0}
                      >
                        <MDBIcon icon="chevron-up" />
                      </button>
                      <button
                        className="down"
                        onClick={this.loadDownImage}
                        disabled={
                          this.state.images.length <= 4
                            ? true
                            : this.state.activeIndex >= this.state.images.length
                            ? true
                            : false
                        }
                      >
                        <MDBIcon icon="chevron-down" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

const captionStyle: CSSProperties = {
  backgroundColor: "rgba(0,0,0,0.4)",
  height: "100%",
  overflow: "hidden",
  position: "absolute",
  // bottom: "50px",
  width: "100%",
  color: "white",
  // color: "black",
  padding: "2px",
  fontFamily: "CaviaDreamsBold",
  fontSize: "90%",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999999,
};

const customTagStyle: CSSProperties = {
  wordWrap: "break-word",
  display: "inline-block",
  backgroundColor: "white",
  height: "auto",
  fontSize: "75%",
  fontWeight: 600,
  lineHeight: "1",
  padding: ".2em .6em .3em",
  borderRadius: ".25em",
  color: "black",
  verticalAlign: "baseline",
  margin: "2px",
};

export default withRouter(WorkPage);
