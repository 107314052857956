import React, { Fragment, useState } from "react";
import ActivityData from "../activities-json";
import "../activities.scss";
import Career from "./career";
import NoResult from "../no_result/no_result";

type CareerListProps = {
  careers: [];
  months: string[];
};

const CareerList = (props: CareerListProps) => {
  const [selectedId, setSelectedId] = useState(0);
  console.log(props.careers);
  const renderCareerList = () => {
    return (
      <>
      <div></div>
        {props.careers && props.careers.length > 0 ? (
          <div className="container" id="introduction">
            <div className="activities" id="blogs">
              {props.careers.map((career:any, index) => {
                const status = selectedId === index;
                return (
                  <Career
                  key={career.id}
                    index={index}
                    career={career}
                    setSelectedId={setSelectedId}
                    status={status}
                    months={props.months}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div>NO result found!!!</div>
        )}
      </>
    );
  };

  return (
    <Fragment>
      {props.careers && props.careers.length > 0 ? (
        renderCareerList()
      ) : (
        <NoResult title="Careers" />
      )}
    </Fragment>
  );
};

export default CareerList;
