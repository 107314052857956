import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./footer.scss";

function Footer(props) {
  const [tooltip1Open, setTooltip1Open] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const toggle1 = () => setTooltip1Open(!tooltip1Open);
  return (
    <div className="footer" id="TooltipExample">
      <div className="message">
        {tooltip1Open ? (
          <div className="tooltip-1">
            &#169; 2020 All rights reserved. ARC &#x24D8;
          </div>
        ) : (
          ""
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <h2 className="footer-heading">Disclaimer:</h2>
          <p className="desc">
            All rights reserved. No part of this website maybe copied, adopted,
            reproduced in any form or by any electronic or mechanical means,
            including information storage and retrieval system without a prior
            written permission of the copyright holders. Any breach will entail
            legal action.
          </p>
          <p className="desc-sub">
            <span>Credits:</span>
            <br /> Designed & Powered By
            <a href="http://www.scieverinc.com" className="made-company">
              {" "}
              Sciever Inc.
            </a>
          </p>
        </ModalBody>
      </Modal>
      <div
        className="cr-logo"
        onMouseEnter={toggle1}
        onMouseLeave={toggle1}
        onClick={toggle}
      >
        &#169;
      </div>
    </div>
  );
}

export default Footer;
