import React, { Fragment, useState, FormEvent } from "react";
import "./contact.scss";
import Layout from "../../common/layout";
import Footer from "../../footer/footer";
import { MDBInput, MDBBtn, MDBIcon } from "mdbreact";
import ReCAPTCHA from "react-google-recaptcha";
import { postMailToCompany } from "../../../api/mail";

type ContactProps = {
  contact: any;
  company: any;
  // id: string,
  // location: string,
  // website_url: string,
  // mapUrl: string,
  // company_id: string,
  // telephone_number: string,
  // cell_number: string,
  // fb_url: string,
  // insta_url: string,
  // youtube_url: string,
  // created_at: string,
  // updated_at: string
};

export default class ContactPage extends React.Component<ContactProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      status: "",
      fullName: "",
      email: "",
      number: "",
      message: "",
      // rowData: this.props.rowData,
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: true,
    };
    this.handleInput = this.handleInput.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleInput = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  resetForm = () => {
    this.setState({
      status: "",
      fullName: "",
      email: "",
      number: "",
      message: "",
    });
  };

  submitForm(ev: FormEvent) {
    ev.preventDefault();
    const form = ev.target as HTMLFormElement;
    const data = new FormData(form);

    postMailToCompany(data)
      .then((res: any) => {
        console.log("Success :: ", res);
        form.reset();
        this.setState({ status: "SUCCESS" });
      })
      .catch((err: any) => {
        this.setState({ status: "ERROR" });
      });
  }

  onChange(value: any) {
    console.log("Captcha value:", value);
    if (value != null) this.setState({ expired: false });
  }

  render() {
    return (
      <Fragment>
        <div className="contact-main">
          <div className="left-page ">
            <div className="contact-form">
              <form
                role="form"
                onSubmit={this.submitForm}
                action="https://formspree.io/f/xdopeozj"
                method="POST"
                className="md-form"
              >
                {/* <MDBInput hint="Full Name *" valueDefault={this.state.fullName} size="sm" name="fullName" onInput={this.handleInput} required />
                <MDBInput hint="Email *" valueDefault={this.state.email} size="sm" name="email" onInput={this.handleInput} required />
                <MDBInput hint="Contact Number *" size="sm" valueDefault={this.state.number} name="number" onInput={this.handleInput} required />
                <MDBInput
                  type="textarea"
                  rows="3"
                  size="sm"
                  name="message"
                  hint="Message *"
                  onInput={this.handleInput}
                  valueDefault={this.state.message}
                  required
                /> */}
                {/* <label htmlFor="defaultFormContactNameEx" className="grey-text">
                                Your Full name
                            </label> */}
                <input
                  type="text"
                  id="FormContactName"
                  name="Name"
                  className="form-control"
                  placeholder="Full Name *"
                  required
                />
                {/* <label htmlFor="defaultFormContactEmailEx" className="grey-text">
                                Your email
                            </label> */}

                <input
                  type="email"
                  id="defaultFormContactEmailEx"
                  name="Email"
                  placeholder="Email *"
                  className="form-control"
                  required
                />
                <input
                  type="number"
                  id="defaultFormContactEx"
                  name="contact"
                  placeholder="Contact Number *"
                  className="form-control"
                  required
                />
                {/* <label
                                htmlFor="defaultFormContactMessageEx"
                                className="grey-text"
                            >
                                Your message
                            </label> */}
                <textarea
                  id="defaultFormContactMessageEx"
                  className="form-control"
                  rows={3}
                  placeholder="Message"
                  name="Message"
                  required
                />

                <ReCAPTCHA
                  style={{ display: "inline-block" }}
                  theme="dark"
                  sitekey="6LeXyd4ZAAAAACi0K6QL4V4MsOkmu8DKKFbeWAfd"
                  onChange={this.onChange}
                />

                <MDBBtn
                  type="submit"
                  className="button-color"
                  disabled={this.state.expired}
                  style={{ position: "absolute", width: "6em" }}
                >
                  Send
                </MDBBtn>
                {/* <MDBBtn className="button-color">Send</MDBBtn> */}
                {this.state.status === "SUCCESS" && (
                  <p>Submitted Successfully.</p>
                )}
                {this.state.status === "ERROR" && (
                  <p>Ooops! There was an error.</p>
                )}
              </form>
            </div>
            <div className="contact-text" style={{ paddingTop: "35px" }}>
              <div style={{ fontWeight: "bold" }}>
                {(this.props.company && this.props.company.name
                  ? this.props.company && this.props.company.name
                  : "") && this.props.company.name.toUpperCase()}
              </div>
              <div style={{ fontWeight: "bold" }}>
                {(this.props.company && this.props.company.description
                  ? this.props.company && this.props.company.description
                  : "") && this.props.company.description}
              </div>
              <div>
                {this.props.contact && this.props.contact.location
                  ? this.props.contact && this.props.contact.location
                  : ""}
              </div>
              <div>
                {this.props.contact && this.props.contact.telephone_number
                  ? this.props.contact && this.props.contact.telephone_number
                  : ""}
                ,
                {this.props.contact && this.props.contact.cell_number
                  ? this.props.contact && this.props.contact.cell_number
                  : ""}
              </div>
              <div>
                {this.props.contact && this.props.contact.website_url
                  ? this.props.contact && this.props.contact.website_url
                  : ""}
              </div>
              <div>
                {this.props.contact && this.props.contact.twitter_url ? (
                  <span id="icon">
                    <a
                      target="_blank"
                      href={
                        this.props.contact && this.props.contact.twitter_url
                      }
                    >
                      <MDBIcon fab icon="twitter" />
                    </a>
                  </span>
                ) : null}

                {this.props.contact && this.props.contact.fb_url ? (
                  <span id="icon">
                    <a
                      target="_blank"
                      href={this.props.contact && this.props.contact.fb_url}
                    >
                      <MDBIcon fab icon="facebook-f" />
                    </a>
                  </span>
                ) : null}

                {this.props.contact && this.props.contact.linked_in_url ? (
                  <span id="icon">
                    <a
                      target="_blank"
                      href={
                        this.props.contact && this.props.contact.linked_in_url
                      }
                    >
                      <MDBIcon fab icon="linkedin-in" />
                    </a>
                  </span>
                ) : null}

                {this.props.contact && this.props.contact.youtube_url ? (
                  <span id="icon">
                    <a
                      target="_blank"
                      href={
                        this.props.contact && this.props.contact.youtube_url
                      }
                    >
                      <MDBIcon fab icon="youtube" />
                    </a>
                  </span>
                ) : null}

                {this.props.contact && this.props.contact.insta_url ? (
                  <span id="icon">
                    <a
                      target="_blank"
                      href={this.props.contact && this.props.contact.insta_url}
                    >
                      <MDBIcon fab icon="instagram" />
                    </a>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="right-page">
            {this.props.contact && this.props.contact.mapUrl ? (
              <iframe
                title="map"
                src={this.props.contact && this.props.contact.mapUrl}
              ></iframe>
            ) : null}
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
